.flashing-stats-container {
    text-align: center;
    background-color: #1a1a2e;
    color: #fff;
    overflow: hidden;
    padding: 4rem 0;
}

.flashing-stats {
    border-radius: 12px;
    border-style: solid;
    box-sizing: border-box;
    margin-bottom: 1em;
    margin-top: 1em;
    max-width: min(700px, 95vw);
    max-height: 300px;
    padding: 1.5rem;
    text-align: center;
    width: 100%;
    display: inline-block;
}

.flashing-stats p {
    font-size: x-large;
    line-height: 2rem;
    color: white;
    text-align: left;
    padding-left: 1em;
}

.flashing-stats-container h2 {
    color: white;
}

.flashing-stats h3 {
    text-align: center;
    font-size: xxx-large;
    line-height: 0;
    color: white;
}

.fade-transition {
  transition: opacity 0.5s ease-in-out;
  display: flex;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}




@media (max-width: 768px) {
    .fade-transition {
        display: inline-block;
    }
    .flashing-stats p {
        padding-left: 0;
        text-align: center;
    }
}
