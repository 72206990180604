.integrations-container {
  padding: 2rem 0;
  width: 100%;
  overflow: hidden;
}

.integrations-scroll {
    display: flex;
    overflow: hidden;
    padding: 1.5rem;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
}

.integration-item:hover {
  transform: translateY(-5px);
}

.integration-item {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    padding: 1rem;
    transition: transform 0.3s ease;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
}


.integration-item p {
    bottom: 0.5em;
    position: absolute;
    margin: 0;
}

.integration-logo {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 3rem;
    margin-top: 1rem;
    padding: 10px;
}


/* Custom scrollbar styling */
.integrations-scroll::-webkit-scrollbar {
  height: 6px;
}

.integrations-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.integrations-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.integrations-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Individual logo styles */
.gusto-logo {
  width: 100px;
  padding: 8px;
}

.bamboohr-logo {
  width: 100px;
  padding: 8px;
}

.greenhouse-logo {
  width: 95px;
  padding: 8px;
}

.workday-logo {
  width: 140px;
  padding: 16px;
}

.servicenow-logo {
  width: 95px;
  padding: 8px;
}

.adp-logo {
  width: 116px;
  padding: 0px;
}

.deel-logo {
  width: 60px;
  padding: 28px;
}

.successfactors-logo {
  width: 110px;
  padding: 0px;
}

.ukg-logo {
  width: 160px;
  padding: 12px;
}

.salesforce-logo {
  width: 120px;
  padding: 10px;
}

.zendesk-logo {
  width: 100px;
  padding: 14px;
}

.okta-logo {
  width: 80px;
  padding: 14px;
}

.box-logo {
  width: 120px;
  padding: 16px;
}

.onedrive-logo {
  width: 150px;
  padding: 4px;
}

.dropbox-logo {
  width: 85px;
  padding: 16px;
}

.drive-logo {
  width: 80px;
  padding: 20px;
}

.sharepoint-logo {
  width: 150px;
  padding: 4px;
}

.calendar-logo {
  width: 75px;
  padding: 18px;
}

.outlook-logo {
  width: 85px;
  padding: 18px;
}

/* Scroll animations — evan@validia.ai, 1/30/25 */
:root {
  --integration-scrolling-animation-duration: 50s;
}

.integration-item-set {
    display: flex;
    gap: 2rem;
    position: relative;
    padding: 1rem;
}

.integration-item-primary-set {
  justify-content: space-around;
  animation: scroll-horizontal var(--integration-scrolling-animation-duration) linear infinite;
}

.integration-item-secondary-set {
  animation: scroll-horizontal var(--integration-scrolling-animation-duration) linear infinite;
}

.integrations-scroll:hover .integration-item-primary-set,
.integrations-scroll:hover .integration-item-secondary-set {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}

@keyframes scroll-horizontal {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
}
