/* DemoViewer.css */
.arc-demo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f4f8;
  padding: 2rem;
  overflow-x: hidden;
  box-sizing: border-box;
}

.arc-demo-container {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.arc-demo-sidebar {
  width: 40%;
  background-color: #f9fafb;
  padding: 3rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.arc-demo-badge {
  display: inline-block;
  background-color: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.arc-demo-title {
  font-size: 2.25rem;
  font-weight: 800;
  color: #0f172a;
  margin-bottom: 1.25rem;
  line-height: 1.2;
}

.arc-demo-description {
  color: #475569;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.arc-demo-features {
  margin-bottom: 2.5rem;
}

.arc-features-title {
  font-size: 1rem;
  font-weight: 700;
  color: #2563eb;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.arc-features-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-left: 0.5rem;
}

.arc-feature-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #475569;
  font-size: 0.95rem;
  text-align: left;
}

.arc-feature-icon {
  color: #2563eb;
  flex-shrink: 0;
}

.arc-demo-cta {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #2563eb;
  color: white;
  border: none;
  padding: 0.875rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.arc-demo-cta:hover {
  background-color: #1d4ed8;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(37, 99, 235, 0.3);
}

.arc-cta-icon {
  transition: transform 0.3s ease;
}

.arc-demo-cta:hover .arc-cta-icon {
  transform: translateX(3px);
}

.arc-demo-main {
  width: 60%;
  background-color: #f1f5f9;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.arc-demo-iframe-wrapper {
  width: 100%;
  max-width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.arc-demo-iframe-wrapper:hover {
  transform: scale(1.01);
}

.arc-demo-iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 1024px) {
  .arc-demo-container {
    flex-direction: column;
    padding: 1.5rem;
  }

  .arc-demo-sidebar,
  .arc-demo-main {
    width: 100%;
  }

  .arc-demo-sidebar {
    padding: 2rem;
  }

  .arc-demo-features {
    margin-bottom: 2rem;
  }

  .arc-demo-iframe-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .arc-demo-main {
      padding-left: 0;
      padding-right: 0;
  }

  .arc-demo-wrapper {
    padding: 0;
  }

  .arc-demo-title {
    font-size: 1.75rem;
    text-align: center;
  }

  .arc-demo-sidebar {
    padding: 1.5rem;
    text-align: center;
  }

  .arc-demo-description {
    text-align: center;
  }

  .arc-demo-iframe-wrapper {
    max-width: 100%;
  }

  .arc-demo-container {
    padding: 0;
    box-sizing: border-box;
  }
}



.arc-demo-cta {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #2563eb;
  color: white;
  border: none;
  padding: 0.875rem 1.5rem;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none; /* Prevent default link underline */
  justify-content: center; /* Ensure text/icon alignment */
  transition: all 0.3s ease;
}

.arc-demo-cta:hover {
  background-color: #1d4ed8;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(37, 99, 235, 0.3);
}

.arc-cta-icon {
  transition: transform 0.3s ease;
}

.arc-demo-cta:hover .arc-cta-icon {
  transform: translateX(3px);
}
