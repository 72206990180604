.page-container {
    min-height: 150vh !important;
    padding: 40px 0 40px 0;
    position: relative;
    width: 100%;
}

.blog-post-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    text-align: left;
    display: none;
}

.blog-post-container.loaded {
    display: block;
    animation: fadeIn 0.8s ease-in forwards;
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    z-index: 1000;
}

.loading-spinner {
    width: 80px;
    height: 80px;
    border: 6px solid #eee;
    border-top: 6px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-text {
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes pulse {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.back-link {
    display: inline-flex;
    align-items: center;
    color: #666;
    text-decoration: none;
    font-size: 1.1rem;
}

.back-link:hover {
    color: #000;
}

.post-header {
    margin-bottom: 40px;
    text-align: left;
}

.post-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    line-height: 1.2;
}

.post-meta {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 30px;
}

.post-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 40px;
}

.post-content {
    line-height: 1.8;
    font-size: 1.1rem;
    color: #333;
    text-align: left;
}

.post-content p {
    margin-bottom: 20px;
    text-align: left;
}

.post-content h2 {
    font-size: 1.8rem;
    margin: 40px 0 20px;
    text-align: left;
}

.post-content h3 {
    font-size: 1.4rem;
    margin: 30px 0 15px;
    text-align: left;
}

.post-content img {
    width: 100%;
    height: auto;
    margin: 30px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.post-content ul, .post-content ol {
    margin: 20px 0;
    padding-left: 20px;
    text-align: left;
}

.post-content li {
    margin-bottom: 10px;
}

.post-content blockquote {
    border-left: 4px solid #ddd;
    padding-left: 20px;
    margin: 30px 0;
    font-style: italic;
    color: #555;
}

.post-content a {
    color: #0066cc;
    text-decoration: none;
}

.post-content a:hover {
    text-decoration: underline;
}

.post-content-image {
    width: 100%;
    height: auto;
    margin: 30px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
    .post-title {
        font-size: 2rem;
    }

    .post-content {
        font-size: 1rem;
    }
}
