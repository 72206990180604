.below-header-banner {
    min-height: 60px;
    background: var(--primary-blue)!important;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 0.5em;
}

.below-header-banner button {
    color: var(--primary-blue);
    margin: 0.5rem;
    margin-left: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}
