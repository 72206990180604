.use-cases-grid {
    display: inline-grid;
    gap: 30px;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    max-width: 1200px;
}

.use-case-card {
    border-radius: 12px;
    border-style: solid;
    box-sizing: border-box;
    margin-bottom: 1em;
    margin-top: 1em;
    max-width: 400px;
    padding: 1.5rem;
    text-align: left;
    width: 100%;
}

@media (max-width: 850px) {
    .use-cases-grid {
        grid-template-columns: 1fr;
    }
}
