/* Styles for .App-header */
.App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1300px) {
    .App-header {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
    }
}

.App-header {
    align-items: center;
    padding: 2rem 2rem;
}

@media (max-width: 768px) {
    .App-header {
        padding: 1rem 1rem;
    }
}

/* Styles for .logo-container */
.logo-container {
    display: flex;
    align-items: center;
    height: 40px;
}

@media (max-width: 768px) {
    .logo-container {
        height: 30px;
    }
}

/* Styles for .logo */
.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-blue);
    height: 40px;
    width: auto;
    max-width: 200px;
}

@media (max-width: 768px) {
    .logo {
        height: 30px;
    }
}

.logo {
    height: 100%;
    width: auto;
    max-width: 150px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .logo {
        max-width: 120px;
    }
}
