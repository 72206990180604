/* Standarize homepage font size and color */
p, li {
    color: var(--text-light);
    font-size: 20px;
}

h3 {
    color: var(--primary-blue);
    font-size: xx-large;
}

h2 {
    font-size: xxl-large;
}
