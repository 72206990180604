.page-container {
    min-height: 150vh !important; /* MUCH BIGGER, FORCING IT */
    padding: 40px 0 300px 0;  /* MASSIVE BOTTOM PADDING */
    position: relative;
    width: 100%;
}

.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: none; /* Hide until loaded */
}

.blog-container.loaded {
    display: block;
    animation: fadeIn 0.8s ease-in forwards;
}

/* MASSIVE LOADING ANIMATION */
.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    z-index: 1000;
}

.loading-spinner {
    width: 80px;  /* BIGGER SPINNER */
    height: 80px;
    border: 6px solid #eee;
    border-top: 6px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-text {
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes pulse {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Rest of your styles exactly the same... */
.featured-post {
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #eee;
}

.featured-post-inner {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 40px;
    align-items: center;
}

.featured-post .post-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 12px;
}

.featured-post .post-content {
    padding: 20px 0;
}

.featured-post .post-meta {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 16px;
}

.featured-post .post-title {
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
    font-weight: 600;
}

.featured-post .post-excerpt {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 24px;
}

.featured-post .read-more {
    display: inline-block;
    font-size: 1.1rem;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    padding: 12px 24px;
    border: 2px solid #000;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.featured-post .read-more:hover {
    background: #000;
    color: white;
}

.posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 40px;
    padding-bottom: 10%;
}

.post-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid #eee;
}

.post-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.post-card .post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.post-card .post-content {
    padding: 20px;
}

.post-card .post-meta {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 10px;
}

.post-card .post-title {
    font-size: 1.4rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.post-card .post-excerpt {
    color: #666;
    line-height: 1.5;
}

.post-card .read-more {
    display: inline-block;
    margin-top: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

.post-card .read-more:hover {
    text-decoration: underline;
}

.section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
}

@media (max-width: 968px) {
    .featured-post-inner {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .featured-post .post-image {
        height: 400px;
    }

    .featured-post .post-title {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .posts-grid {
        grid-template-columns: 1fr;
    }
}
