@import "./fonts.css";

:root {
    --primary-blue: #022f6b;
    --text-dark: #172b4d;
    --text-light: #42526e;
    --background-light: #f4f5f7;
}

body {
    margin: 0;
    font-family: "main", sans-serif;
    color: var(--text-dark);
    background-color: white;
}

html,
body {
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
}

nav ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin: 0 1rem;
}

nav ul li a {
    text-decoration: none;
    color: var(--text-light);
}

.cta-buttons button {
    margin-left: 1rem;
}

.primary-button {
    background-color: var(--primary-blue) !important;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.secondary-button {
    background-color: white;
    color: var(--primary-blue) !important;
    border: 1px solid var(--primary-blue) !important;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.trusted-by {
    padding: 2rem;
    background-color: white;
}

.trusted-by p {
    color: var(--text-light);
    margin-bottom: 1rem;
}

.client-logos {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.testimonial-bar {
    background-color: var(--primary-blue);
    color: white;
    overflow: hidden;
    padding: 20px 0;
}

.testimonial-track {
    display: flex;
    animation: scroll 30s linear infinite;
}

.testimonial-item {
    flex: 0 0 auto;
    width: 300px;
    padding: 0 20px;
    text-align: center;
}

.testimonial-item p {
    margin: 0;
    font-style: italic;
    white-space: normal;
}

.testimonial-item span {
    font-size: 0.8em;
    opacity: 0.8;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-300px * 3));
    }
}

@media (max-width: 768px) {
    .testimonial-item {
        width: 250px;
    }

    @keyframes scroll {
        100% {
            transform: translateX(calc(-250px * 5));
        }
    }
}

.unified-platform {
    padding: 4rem 2rem;
    background-color: var(--background-light);
}

.unified-platform h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.product-selector {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
    padding-top: 1rem;
}

.product-button {
    background-color: white;
    color: var(--primary-blue);
    border: 2px solid var(--primary-blue);
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
}

.product-button:hover,
.product-button.active {
    background-color: var(--primary-blue);
    color: white;
}

.product-details-section {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    margin-top: 2rem;
    transition: all 0.3s ease;
}

.product-content {
    display: flex;
    gap: 4rem;
    align-items: flex-start;
}

.product-text {
    flex: 1;
}

.product-text h3 {
    margin-top: 0;
    color: var(--primary-blue);
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.product-tagline {
    font-size: 1.2rem;
    color: var(--text-light);
    margin-bottom: 1rem;
    font-style: italic;
}

.product-description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    line-height: 1.6;
}

.product-text h4 {
    font-size: 1.3rem;
    color: var(--primary-blue);
    margin-bottom: 1rem;
}

.product-details {
    padding-left: 1.5rem;
    margin-bottom: 0;
}

.product-details li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.5rem;
}

.product-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .product-content {
        flex-direction: column;
    }

    .product-image img {
        max-height: 300px;
        margin-top: 2rem;
    }
}

/* ... previous styles ... */

.product-image-slideshow {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-image-slideshow img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.slideshow-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.slideshow-controls button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    transition: background-color 0.3s ease;
}

.slideshow-controls button:hover {
    background-color: #0043a4;
}

.slideshow-controls span {
    font-size: 0.9rem;
    color: var(--text-light);
}

@media (max-width: 768px) {
    .product-image-slideshow img {
        max-height: 300px;
        margin-top: 2rem;
    }
}

.product-details {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.product-details li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.5rem;
}

.product-image-slideshow {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-image-slideshow img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.slide-description {
    margin-top: 1rem;
    font-style: italic;
    color: var(--text-light);
    text-align: center;
}

.slideshow-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.slideshow-controls button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    transition: background-color 0.3s ease;
}

.slideshow-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.slideshow-controls button:hover:not(:disabled) {
    background-color: #0043a4;
}

.slideshow-controls span {
    font-size: 0.9rem;
    color: var(--text-light);
}

@media (max-width: 768px) {
    .product-image-slideshow img {
        max-height: 200px;
        margin-top: 2rem;
    }
}

.team-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.team-section h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, min(250px));
    gap: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center;
}

.team-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.team-member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

/* Remove border radius on default image because we use the Validia logo */
.team-member-image.default {
    border-radius: 0;
}

.team-card h3 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--primary-blue);
}

.team-card p {
    margin: 0.5rem 0 1rem;
    font-size: 1rem;
    color: var(--text-light);
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.social-icon {
    font-size: 1.5rem;
    color: var(--primary-blue);
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #0043a4;
}

@media (max-width: 768px) {
    .team-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

.integrations-section {
    padding: 6rem 3rem;
    /* Increased padding */
    background-color: #f0f4f8;
}

.integrations-section h2 {
    text-align: center;
    margin-bottom: 3rem;
    /* Increased margin */
    font-size: 3rem;
    /* Larger font size */
    color: var(--primary-blue);
}

.integrations-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.25rem;
    /* Increased gap */
    max-width: 1500px;
    /* Increased max-width */
    margin: 0 auto;
}

.integration-item {
    background-color: white;
    border-radius: 15px;
    /* Slightly larger border radius */
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 2.25rem;
    /* Increased padding */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.integration-item:hover {
    transform: translateY(-7px);
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.15);
}

.integration-item.highlight {
    grid-column: span 2;
    background-color: var(--primary-blue);
    color: white;
}

.integration-item.highlight h3 {
    font-size: 2.25rem;
    /* Larger font size */
    margin: 0;
}

.integration-item.logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    /* Increased gap */
    align-items: center;
    justify-items: center;
}

.integration-item.logos img {
    max-width: 100%;
    max-height: 60px;
    /* Larger max-height for logos */
    object-fit: contain;
}

.integration-item.feature h4 {
    font-size: 1.8rem;
    /* Larger font size */
    margin-bottom: 0.75rem;
    color: var(--primary-blue);
}

.integration-item.feature p {
    margin: 0;
    color: var(--text-light);
    font-size: 1.2rem;
    /* Larger font size */
}

.integration-item.cta {
    background-color: #e6f0ff;
}

.integration-item.cta h4 {
    font-size: 1.8rem;
    /* Larger font size */
    margin-bottom: 1.5rem;
    color: var(--primary-blue);
}

.integration-cta-button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 1.125rem 2.25rem;
    /* Larger padding */
    font-size: 1.5rem;
    /* Larger font size */
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.integration-cta-button:hover {
    background-color: #0043a4;
}

@media (max-width: 1200px) {
    .integrations-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .integration-item.highlight {
        grid-column: span 2;
    }
}

@media (max-width: 768px) {
    .integrations-grid {
        grid-template-columns: 1fr;
    }

    .integration-item.highlight {
        grid-column: span 1;
    }

    .integrations-section {
        padding: 4rem 2rem;
        /* Slightly reduced padding for mobile */
    }

    .integration-item {
        padding: 1.5rem;
        /* Slightly reduced padding for mobile */
    }

    .integration-item.highlight h3 {
        font-size: 1.8rem;
        /* Slightly smaller font size for mobile */
    }
}

.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 2rem;
    background-color: var(--background-light);
}

.hero-content {
    flex: 1;
    max-width: 600px;
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.hero p {
    font-size: 1.1rem;
    color: var(--text-light);
    margin-bottom: 2rem;
}

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        text-align: center;
        padding: 2rem 1rem;
    }

    .hero-content {
        margin-bottom: 2rem;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .hero-image {
        width: 100%;
    }

    .hero-image video {
        width: 100%;
    }
}

/* Contact Section */
.contact-section {
    padding: 2rem 2rem;
    background-color: #f0f4f8;
}

.contact-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.contact-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-form {
    flex: 1;
    max-width: 500px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.contact-form textarea {
    height: 150px;
    resize: vertical;
}

.submit-button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0043a4;
}

.contact-info {
    flex: 1;
    max-width: 400px;
    padding-left: 2rem;
}

.contact-info h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.contact-details p {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.contact-details svg {
    margin-right: 0.5rem;
}

/* Footer */
.footer {
    background-color: var(--primary-blue);
    color: white;
    padding: 3rem 2rem 1rem;
    padding-bottom: 2rem;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.footer-links {
    max-width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links a {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    transition: opacity 0.3s ease;
}

.footer-links a:hover {
    opacity: 0.8;
}

.social-links a {
    color: white;
    font-size: 1.5rem;
    margin-left: 1rem;
    transition: opacity 0.3s ease;
}

.social-links a:hover {
    opacity: 0.8;
}

.footer-bottom {
    text-align: center;
    padding-top: 1rem;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
    }

    .contact-form,
    .contact-info {
        max-width: 100%;
    }

    .contact-info {
        padding-left: 0;
        margin-top: 2rem;
    }

    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-links,
    .social-links {
        margin-top: 1rem;
    }

    .footer-links a,
    .social-links a {
        margin: 0 0.5rem;
    }
}

.contact-section {
    padding: 2rem 2rem;
    background-color: #f8f9fa;
}

.contact-container {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    padding-top: 3rem;
    margin-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    max-width: 600px;
}

.contact-section h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.contact-intro {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--text-light);
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    position: relative;
    margin-bottom: 2rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem 0;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    transition: border-color 0.3s;
}

.form-group label {
    position: absolute;
    top: 0.75rem;
    left: 0;
    font-size: 1rem;
    color: #777;
    transition: all 0.3s;
    pointer-events: none;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-bottom-color: var(--primary-blue);
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
    top: -1.25rem;
    font-size: 0.8rem;
    color: var(--primary-blue);
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.submit-button {
    align-self: flex-start;
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
}

.submit-button svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.submit-button:hover {
    background-color: #0043a4;
    transform: translateY(-2px);
}

.submit-button:hover svg {
    transform: translateX(5px);
}

@media (max-width: 768px) {
    .contact-section {
        padding: 4rem 1rem;
    }
}

.contact-section {
    padding: 2rem 2rem;
    background-color: #f8f9fa;
}

.contact-section h2 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.contact-intro {
    text-align: center;
    margin-bottom: 3rem;
    color: var(--text-light);
}

.contact-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-form {
    flex: 1;
    max-width: 500px;
    margin-right: 2rem;
}

.form-group {
    position: relative;
    margin-bottom: 2rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem 0;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    transition: border-color 0.3s;
}

.form-group label {
    position: absolute;
    top: 0.75rem;
    left: 0;
    font-size: 1rem;
    color: #777;
    transition: all 0.3s;
    pointer-events: none;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-bottom-color: var(--primary-blue);
}

.form-group input:focus + label,
.form-group textarea:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:not(:placeholder-shown) + label {
    top: -1.25rem;
    font-size: 0.8rem;
    color: var(--primary-blue);
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.submit-button {
    background-color: var(--primary-blue);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
}

.submit-button svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.submit-button:hover {
    background-color: #0043a4;
    transform: translateY(-2px);
}

.submit-button:hover svg {
    transform: translateX(5px);
}

.contact-info {
    flex: 1;
    max-width: 400px;
    padding-left: 2rem;
    border-left: 1px solid #e0e0e0;
}

.contact-info h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.contact-info p {
    margin-bottom: 1.5rem;
    color: var(--text-light);
}

.contact-info ul {
    list-style-type: none;
    padding: 0;
}

.contact-info li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--text-light);
}

.contact-info li svg {
    margin-right: 0.5rem;
    color: var(--primary-blue);
}

.contact-info a {
    color: var(--text-light);
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-info a:hover {
    color: var(--primary-blue);
}

@media (max-width: 768px) {
    .contact-section {
        padding: 4rem 1rem;
    }

    .contact-container {
        flex-direction: column;
    }

    .contact-form {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 3rem;
    }

    .contact-info {
        max-width: 100%;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid #e0e0e0;
        padding-top: 2rem;
    }
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    /* Ensure the hero takes up the full viewport height */
}

.hero-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Add a semi-transparent overlay */
    z-index: 1;
}

.hero-content,
.hero-image {
    position: relative;
    z-index: 2;
}

.hero-content {
    padding: 2rem;
    max-width: 600px;
}

.hero h1,
.hero p {
    color: white;
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image video {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
    }

    .hero-content,
    .hero-image {
        width: 100%;
    }
}

.comprehensive-protection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 2rem;
    background-color: #f8f9fa;
}

.protection-content {
    flex: 1;
    max-width: 600px;
}

.protection-content h2 {
    font-size: 2.5rem;
    color: var(--primary-blue);
    margin-bottom: 2rem;
}

.protection-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.protection-item {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.protection-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.protection-item h3 {
    font-size: 1.2rem;
    color: var(--primary-blue);
    margin-bottom: 0.5rem;
}

.protection-item p {
    font-size: 1rem;
    color: var(--text-light);
}

.protection-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4rem;
}

.protection-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
    .comprehensive-protection {
        flex-direction: column;
        padding: 4rem 2rem;
    }

    .protection-content {
        max-width: 100%;
        margin-bottom: 3rem;
    }

    .protection-image {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .protection-grid {
        grid-template-columns: 1fr;
    }
}

.key-stats {
    background-color: #1a1a2e;
    color: white;
    padding: 4rem 0;
    overflow: hidden;
}

.key-stats h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #4cc9f0;
}

.stats-container {
    overflow: hidden;
    position: relative;
    padding: 2rem 0;
}

.stat-card {
    flex: 0 0 auto;
    width: 300px;
    margin: 0 1rem;
    background: linear-gradient(135deg, #16213e 0%, #0f3460 100%);
    border-radius: 15px;
    padding: 1.5rem;
    text-align: center;
}

.stat-number {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #4cc9f0;
}

.stat-number span {
    font-size: 2rem;
    vertical-align: super;
}

.stat-card p {
    font-size: 1rem;
    line-height: 1.4;
}

@keyframes scrollStats {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) {
    .stat-card {
        width: 250px;
    }
}

.testimonial-section {
    padding: 4rem 2rem;
    background-color: #f0f4f8;
    text-align: center;
}

.testimonial-section h2 {
    font-size: 2.5rem;
    color: var(--primary-blue);
    margin-bottom: 2rem;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.selector-button {
    background-color: transparent;
    border: 2px solid var(--primary-blue);
    color: var(--primary-blue);
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 0.5rem;
}

.selector-button.active {
    background-color: var(--primary-blue);
    color: white;
}

.testimonial-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.testimonial-card {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    position: relative;
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-10px);
}

.quote-icon {
    font-size: 4rem;
    color: var(--primary-blue);
    position: absolute;
    top: -20px;
    left: 20px;
    opacity: 0.1;
}

.testimonial-text {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #333;
}

.testimonial-author {
    font-weight: bold;
    color: var(--primary-blue);
}

@media (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
        align-items: center;
    }

    .testimonial-card {
        width: 100%;
    }
}

.testimonial-showcase {
    background-color: #f8f9fa;
    padding: 6rem 0;
    position: relative;
}

.testimonial-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.selector-button {
    background-color: transparent;
    border: none;
    color: #777;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.selector-button::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--primary-blue);
    transition: all 0.3s ease;
}

.selector-button.active {
    color: #4361ee;
}

.selector-button.active::after {
    width: 80%;
    left: 10%;
}

.testimonial-showcase {
    background-color: #f8f9fa;
    padding: 6rem 0;
    position: relative;
}

.testimonial-showcase::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(90deg, #4cc9f0, #4361ee, #3a0ca3);
}

.testimonial-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.testimonial-showcase h2 {
    font-size: 2.5rem;
    color: var(--primary-blue);
    margin-bottom: 3rem;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.selector-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.selector-item svg {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #777;
    transition: all 0.3s ease;
}

.selector-item span {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #777;
    text-transform: capitalize;
}

.selector-item.active svg {
    background-color: var(--primary-blue);
    color: white;
    transform: scale(1.1);
}

.selector-item.active span {
    color: var(--primary-blue);
    font-weight: bold;
}

.testimonial-display {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.testimonial-text {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1rem;
}

.testimonial-author {
    font-weight: bold;
    color: #4361ee;
}

.faq-content {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.faq-display {
    background-color: white;
    border-radius: 10px;
    padding: 0.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.faq-question {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1rem;
}

.faq-response {
    font-weight: bold;
    color: #4361ee;
}

.faq-list-item {
    color: #4361ee;
    font-size: medium;
}

.about-us-display {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.about-us-paragraph {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .testimonial-showcase {
        padding: 4rem 1rem;
    }

    .testimonial-selector {
        flex-direction: column;
        align-items: center;
    }

    .selector-item {
        margin: 1rem 0;
    }

    .testimonial-text {
        font-size: 1rem;
    }
}

.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4rem 2rem;
    overflow: hidden;
    min-height: 80vh;
}

.hero-content {
    flex: 1 1 auto;
    max-width: 600px;
    position: relative;
    z-index: 1;
    margin-top: 2rem;
    margin-left: 2rem;
}

.hero-bottom {
    align-items: right !important;
    color: var(--primary-blue);
    z-index: 1;
    padding-top: 10px !important;
    min-width: 100% !important;
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
    text-align: left;
}

.hero p {
    font-size: 1.1rem;
    color: var(--text-dark);
    margin-bottom: 2rem;
    text-align: left;
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.hero-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* Adjust opacity as needed */
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2;
    /* ... other styles */
}

@media (max-width: 768px) {
    .hero {
        min-height: 80vh;
        padding: 2rem 1rem;
    }

    .hero-content {
        max-width: 100%;
        text-align: center;
    }

    .hero h1 {
        font-size: 3rem;
    }

    .hero p {
        font-size: 2rem;
    }

    .hero .primary-button {
        width: 100%;
        margin-top: 1rem;
    }
}

.menu-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: var(--primary-blue);
    cursor: pointer;
}

@media (max-width: 1300px) {
    .menu-toggle {
        display: block;
        position: absolute;
        right: 1rem;
    }

    nav {
        width: 100%;
        display: none;
    }

    nav.show {
        display: block;
    }

    nav ul {
        flex-direction: column;
        width: 100%;
    }

    nav ul li {
        margin: 0.5rem 0;
    }

    .cta-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }
}

.mobile-cta {
    display: none;
}

@media (max-width: 1300px) {
    nav {
        width: 100%;
        display: none;
    }

    nav.show {
        display: block;
    }

    nav ul {
        flex-direction: column;
        width: 100%;
    }

    nav ul li {
        margin: 0.5rem 0;
    }

    .mobile-cta {
        display: block;
        margin-top: 1rem;
    }

    .mobile-cta button {
        width: 100%;
        max-width: 300px;
    }

    .desktop-cta {
        display: none;
    }
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    align-items: left;
}

.hero-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2;
    max-width: 600px;
    padding: 2rem;
    margin-left: 2.5%;
    /* This will push the content to the left */
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--primary-blue);
}

.hero p {
    font-size: x-large;
    display: inline-block;
    color: var(--text-dark);
    margin-bottom: 2rem;
    display: inline-block;
}

.hero .primary-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
}

@media (max-width: 768px) {
    .hero {
        justify-content: center;
    }

    .hero-content {
        margin-left: 0;
        /* Remove the left margin on mobile */
        text-align: center;
        padding: 1rem;
    }

    .hero h1 {
        font-size: 1.8rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .hero .primary-button {
        width: 100%;
        max-width: 250px;
    }
}

.comprehensive-protection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 2rem;
    background-color: #f8f9fa;
}

.protection-content {
    flex: 1;
    max-width: 50%;
}

.protection-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.protection-item {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.protection-animation {
    flex: 1;
    position: relative;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation-circle {
    width: 400px;
    height: 400px;
    border: 2px solid var(--primary-blue);
    border-radius: 50%;
    animation: pulse 4s infinite;
}

.animation-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.animation-item {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: var(--primary-blue);
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    animation: orbit 10s linear infinite;
}

.item1 {
    animation-delay: 0s;
}

.item2 {
    animation-delay: -2.5s;
}

.item3 {
    animation-delay: -5s;
}

.item4 {
    animation-delay: -7.5s;
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
        opacity: 0.5;
    }

    50% {
        transform: scale(1.05);
        opacity: 1;
    }
}

@keyframes orbit {
    0% {
        transform: rotate(0deg) translateX(220px) rotate(0deg);
    }

    100% {
        transform: rotate(360deg) translateX(220px) rotate(-360deg);
    }
}

@media (max-width: 1200px) {
    .comprehensive-protection {
        flex-direction: column;
        padding: 4rem 1rem;
    }

    .protection-content {
        max-width: 100%;
        margin-bottom: 4rem;
    }

    .protection-animation {
        height: 500px;
        width: 100%;
    }

    .animation-circle {
        width: 300px;
        height: 300px;
    }

    .animation-item {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    @keyframes orbit {
        0% {
            transform: rotate(0deg) translateX(170px) rotate(0deg);
        }

        100% {
            transform: rotate(360deg) translateX(170px) rotate(-360deg);
        }
    }
}

@media (max-width: 768px) {
    .protection-grid {
        grid-template-columns: 1fr;
    }

    .protection-animation {
        height: 300px;
    }

    .animation-circle {
        width: 200px;
        height: 200px;
    }

    .animation-logo {
        width: 80px;
        height: 80px;
    }

    .animation-item {
        width: 40px;
        height: 40px;
        font-size: 1rem;
    }

    @keyframes orbit {
        0% {
            transform: rotate(0deg) translateX(110px) rotate(0deg);
        }

        100% {
            transform: rotate(360deg) translateX(110px) rotate(-360deg);
        }
    }
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Change to flex-start */
    margin-bottom: 3rem;
}

.selector-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 120px;
    /* Set a fixed width for each item */
}

.selector-item svg {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #777;
    transition: all 0.3s ease;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector-item span {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #777;
    text-transform: capitalize;
    text-align: center;
    height: 2.5em;
    /* Set a fixed height for two lines of text */
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector-item.active svg {
    background-color: var(--primary-blue);
    color: white;
    transform: scale(1.1);
}

.selector-item.active span {
    color: var(--primary-blue);
    font-weight: bold;
}

.testimonial-selector {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 3rem;
}

.selector-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 120px;
}

.selector-item svg {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #777;
    transition: all 0.3s ease;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    /* Increase space between icon and text */
}

.selector-item span {
    font-size: 0.9rem;
    color: #777;
    text-transform: capitalize;
    text-align: center;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selector-item.active svg {
    background-color: var(--primary-blue);
    color: white;
    transform: scale(1.1);
}

.selector-item.active span {
    color: var(--primary-blue);
    font-weight: bold;
}

@media (max-width: 768px) {
    .testimonial-selector {
        flex-direction: column;
        align-items: center;
    }

    .selector-item {
        width: 100%;
        max-width: 200px;
        margin-bottom: 1.5rem;
    }

    .selector-item svg {
        margin-bottom: 0.5rem;
        /* Reduce space on mobile */
    }

    .selector-item span {
        height: auto;
        /* Allow text to wrap naturally on mobile */
    }
}

.product-details {
    margin-top: 2rem;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.feature-item:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    color: #4361ee;
    font-size: 1.2rem;
    margin-right: 1rem;
    flex-shrink: 0;
}

.feature-text {
    flex: 1;
}

@media (max-width: 768px) {
    .feature-item {
        padding: 0.75rem;
    }

    .feature-icon {
        font-size: 1rem;
        margin-right: 0.75rem;
    }

    .feature-text {
        font-size: 0.9rem;
    }
}

.blog-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.blog-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.blog-posts-container {
    overflow-x: auto;
    padding-bottom: 1rem;
}

.blog-posts {
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

.substack-post-embed {
    flex: 0 0 auto;
    width: 300px;
    border: 1px solid #e0e0e0;
    padding: 1rem;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.substack-post-embed:hover {
    transform: translateY(-5px);
}

.substack-post-embed p:first-child {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.substack-post-embed p:nth-child(2) {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.substack-post-embed a {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--primary-blue);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.substack-post-embed a:hover {
    background-color: #0043a4;
}

@media (max-width: 768px) {
    .blog-section {
        padding: 3rem 1rem;
    }

    .blog-posts-container {
        margin: 0 -1rem;
    }

    .substack-post-embed {
        width: 260px;
    }
}

.blog-section {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.blog-section h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
}

.blog-posts-container {
    overflow-x: auto;
    padding-bottom: 1rem;
}

.blog-posts {
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

.blog-post-card {
    flex: 0 0 auto;
    width: 300px;
    border: 1px solid #e0e0e0;
    padding: 1.5rem;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.blog-post-card:hover {
    transform: translateY(-5px);
}

.blog-post-card h3 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: var(--primary-blue);
}

.blog-post-card p {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.blog-post-card a {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--primary-blue);
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.blog-post-card a:hover {
    background-color: #0043a4;
}

@media (max-width: 768px) {
    .blog-section {
        padding: 3rem 1rem;
    }

    .blog-posts-container {
        margin: 0 -1rem;
    }

    .blog-post-card {
        width: 260px;
    }
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    align-items: left;
    /* Changed from 'left' to 'center' for vertical alignment */
    justify-content: flex-start;
    /* Aligns content to the left horizontally */
}

.hero-content {
    position: relative;
    z-index: 2;
    max-width: 600px;
    padding: 2rem;
    margin-left: 2.5%;
    text-align: left;
    /* Explicitly set text alignment to left for larger screens */
}

.hero-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .hero {
        min-height: 60vh;
        justify-content: center;
        /* Center content horizontally on mobile */
        padding: 1rem 0;
        align-items: center;
    }

    .hero-content {
        margin-left: 0;
        padding: 1rem;
        text-align: center;
        /* Center text on mobile */
        width: 100%;
        max-width: none;
    }

    .hero h1 {
        font-size: 2rem;
        text-align: center;
        /* Ensure heading is centered on mobile */
    }

    .hero p {
        font-size: 1rem;
        text-align: center;
        /* Ensure paragraph is centered on mobile */
    }

    .hero-background-video {
        height: 60vh;
    }

    .hero {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .hero + section {
        margin-top: 0;
        padding-top: 0;
    }
}

/* For even smaller screens */
@media (max-width: 480px) {
    .hero {
        min-height: 60vh;
    }

    .hero-background-video {
        height: 60vh;
    }
}

.case-study-section {
    padding: 6rem 0;
    background-color: #f8f9fa;
    color: var(--text-dark);
    overflow: hidden;
}

.case-study-section h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: var(--primary-blue);
    font-weight: bold;
}

.case-study-carousel {
    position: relative;
    padding: 0 4rem;
}

.case-study-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    padding: 1rem 0;
}

.case-study-container::-webkit-scrollbar {
    display: none;
    /* WebKit */
}

.case-study-card {
    flex: 0 0 80%;
    max-width: 800px;
    height: 400px;
    margin-right: 2rem;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    scroll-snap-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.case-study-card:hover {
    transform: scale(1.05);
}

.case-study-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;
}

.case-study-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.7)
    );
}

.case-study-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    color: white;
    z-index: 1;
    transition: transform 0.3s ease;
}

.case-study-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.case-study-card p {
    font-size: 1rem;
    margin-bottom: 1rem;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.case-study-card:hover p {
    opacity: 1;
}

.play-button {
    display: inline-flex;
    align-items: center;
    background-color: var(--primary-blue);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.play-button svg {
    margin-right: 0.5rem;
}

.play-button:hover {
    background-color: #0043a4;
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--primary-blue);
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 2;
}

.scroll-button:hover {
    background-color: white;
}

.scroll-button.left {
    left: 1rem;
}

.scroll-button.right {
    right: 1rem;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.video-container iframe {
    width: 90%;
    height: 90%;
}

@media (max-width: 768px) {
    .case-study-section {
        padding: 4rem 0;
    }

    .case-study-card {
        flex: 0 0 90%;
        height: 300px;
    }

    .case-study-carousel {
        padding: 0 2rem;
    }

    .scroll-button {
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
    }
}

.case-study-content {
    /* Keep existing styles */
    width: 100%;
    box-sizing: border-box;
}

.case-study-card h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.case-study-card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .hero {
        min-height: 60vh;
        justify-content: center;
        /* Center content horizontally on mobile */
        padding: 1rem 0;
        align-items: center;
    }

    .hero-content {
        margin-left: 0;
        padding: 1rem;
        text-align: center;
        /* Center text on mobile */
        width: 100%;
        max-width: none;
    }

    .hero h1 {
        font-size: 2rem;
        text-align: center;
        /* Ensure heading is centered on mobile */
    }

    .hero p {
        font-size: 1rem;
        text-align: center;
        /* Ensure paragraph is centered on mobile */
    }

    .hero-background-video {
        height: 60vh;
    }

    .hero {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .hero + section {
        margin-top: 0;
        padding-top: 0;
    }

    .hero h1 {
        font-size: 1.8rem;
        /* Reduced size for mobile */
        text-align: center;
        margin-bottom: 0.75rem;
        /* Slightly reduced margin */
    }

    .hero p {
        font-size: 0.9rem;
        /* Reduced size for mobile */
        text-align: center;
    }
}

.flag-language-selector {
    position: relative;
    display: inline-block;
    z-index: 1000;
}

.selected-flag {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.selected-flag:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.flag-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 40px;
}

.flag-options button {
    background: none;
    border: none;
    font-size: 24px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.flag-options button:hover {
    background-color: #f5f5f5;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .flag-language-selector {
        display: none;
        /* Hide from navbar on mobile */
    }

    /* Style for flag selector in mobile menu */
    .mobile-menu .flag-language-selector {
        display: block;
        margin-top: 10px;
    }

    .mobile-menu .flag-options {
        position: static;
        border: none;
        box-shadow: none;
        background-color: transparent;
    }
}

.desktop-language-selector {
    margin-left: 1rem;
}

.flag-language-selector {
    position: relative;
    display: inline-block;
}

.selected-flag {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.selected-flag:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.flag-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 40px;
    z-index: 1000;
}

.flag-options button {
    background: none;
    border: none;
    font-size: 24px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.flag-options button:hover {
    background-color: #f5f5f5;
}

.mobile-language-selector {
    display: none;
}

@media (max-width: 1300px) {
    .desktop-language-selector {
        display: none;
    }

    .mobile-language-selector {
        display: block;
        margin-top: 1rem;
    }

    .mobile-language-selector .flag-options {
        position: static;
        background-color: transparent;
        border: none;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .mobile-language-selector .flag-options button {
        padding: 5px;
    }
}

.schedule-demo-page .hero-overlay {
    display: none;
}

.hero {
    position: relative;
    overflow: hidden;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4rem 2rem;
}

.hero-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 0;
}

.hero-content {
    position: relative;
    z-index: 1;
    max-width: 600px;
}

@media (max-width: 768px) {
    .hero {
        align-items: center;
    }

    .hero-content {
        text-align: center;
        padding: 1rem;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .hero .primary-button,
    .hero .secondary-button {
        width: 100%;
        max-width: 250px;
    }
}

@media (max-width: 768px) {
    .integration-logos img {
        height: 30px;
        margin: 0.3rem;
    }
}

@media (max-width: 768px) {
    html,
    body {
        overflow-x: hidden;
        width: 100%;
        position: relative;
    }

    .App {
        overflow-x: hidden;
    }

    /* ... other existing mobile styles ... */
}

@media (max-width: 768px) {
    .faq-content {
        padding: 2rem 1.5rem;
    }

    .faq-display {
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .faq-question {
        font-size: 1rem;
        margin-bottom: 0.75rem;
    }

    .faq-response {
        font-size: 0.9rem;
    }

    .faq-list-item {
        font-size: 0.85rem;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 768px) {
    .footer-bottom {
        font-size: 0.8rem; /* Reduce font size for mobile */
        padding: 1rem; /* Add padding for better spacing */
    }

    .footer-content {
        flex-direction: column; /* Stack items vertically */
        text-align: center; /* Center text */
    }

    .footer-links,
    .social-links {
        margin-top: 1rem; /* Add margin for spacing */
    }

    .footer-links a {
        margin: 0 0.4rem; /* Adjust margin for links */
    }

    .social-links a {
        margin: 0 0.5rem; /* Adjust margin for links */
        font-size: 0.8rem; /* Reduce font size for links */
    }
}

@media (max-width: 768px) {
    .products-section {
        padding: 2rem 1rem; /* Reduce padding for mobile */
    }

    .product-toggle {
        display: flex;
        justify-content: center; /* Center the toggle buttons */
        margin-bottom: 1rem; /* Add some space below the toggle */
    }

    .product-toggle button {
        flex: 1; /* Make buttons take equal space */
        margin: 0 0.5rem; /* Add margin between buttons */
        font-size: 1rem; /* Adjust font size for mobile */
    }

    .product-images {
        display: flex;
        flex-direction: column; /* Stack images vertically */
        align-items: center; /* Center images */
        margin-top: 1rem; /* Add space above images */
    }

    .product-images img {
        max-width: 95%; /* Make images larger */
        max-height: 90%;
        height: auto; /* Maintain aspect ratio */
        margin-bottom: 1rem; /* Add space between images */
    }

    .product-description {
        font-size: 0.9rem; /* Reduce font size for descriptions */
        text-align: center; /* Center text */
        padding: 0 1rem; /* Add padding for better readability */
    }
}

.stats-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 1em;
    padding-right: 1em
}

.stats-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.stats-logo {
    width: 180px;
    height: 80px;
    object-fit: contain;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.stats-logo:hover {
    opacity: 1;
}

.stats-logo-text {
    color: #4cc9f0;
    font-size: 0.9rem;
    text-align: center;
    margin: 0;
}

@media (max-width: 768px) {
    .stats-logos {
        flex-direction: column;
        height: auto;
        gap: 2rem;
    }

    .stats-logo {
        height: 50px;
    }

    .stats-logo-text {
        font-size: 0.8rem;
    }
}

.award-banner {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
}

.award-banner img {
    height: 16px;
    width: auto;
    display: block;
}

.award-banner:hover {
    background-color: rgba(255, 255, 255, 0.95);
    transform: translateY(-1px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.award-banner .divider {
    height: 16px;
    width: 1px;
    background-color: #e2e8f0;
}

@media (max-width: 768px) {
    .award-banner {
        padding: 0.4rem 0.8rem;
        gap: 0.75rem;
    }
}

.App {
    overflow-x: hidden;
    position: relative;
    width: 100%;
}

.faq-response,
.faq-list-item {
    padding-left: 3rem; /* Increased horizontal padding */
    padding-right: 3rem; /* Increased horizontal padding */
    margin-bottom: 1rem;
    color: #4361ee;
}

/* For mobile screens, reduce the padding */
@media (max-width: 768px) {
    .faq-response,
    .faq-list-item {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.faq-question-container {
    width: 100%;
    transition: background-color 0.2s ease;
}

.faq-question-container:hover {
    background-color: rgba(67, 97, 238, 0.05);
}

.faq-question {
    margin: 0;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left; /* Ensure text is always left-aligned */
}

.faq-question span {
    flex: 1;
    text-align: left; /* Ensure text is always left-aligned */
}

.faq-response,
.faq-list-item {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-bottom: 1rem;
    color: #4361ee;
    text-align: left; /* Ensure text is always left-aligned */
}

@media (max-width: 768px) {
    .faq-response,
    .faq-list-item {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .faq-question {
        padding: 0.75rem 1rem;
    }

    /* Ensure text stays left-aligned on mobile */
    .faq-question,
    .faq-question span,
    .faq-response,
    .faq-list-item {
        text-align: left;
    }
}
