.social-share-container {
    text-align: center;
    margin-top: 20px;
}

.social-share-container .heading {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.social-share-container .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.social-share-container .button {
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    color: #fff;
}
