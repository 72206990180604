.know-your-section {
  @apply py-8 bg-gradient-to-b from-gray-50 to-white;
}
.know-your-section .container {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}
.know-your-section .grid-layout {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-12 items-center;
}
.know-your-section .content {
  @apply space-y-8 lg:pr-24;
}
.know-your-section .title {
  @apply text-4xl font-bold text-gray-900 lg:-ml-4;
}
.know-your-section .title .dynamic-text {
  @apply text-blue-600 inline-block transition-opacity duration-500 ml-0;
}
.know-your-section .title .dynamic-text.fade {
  @apply opacity-0;
}
.know-your-section .description {
  @apply leading-relaxed text-gray-600 lg:-ml-4;
  font-size: x-large;
}
.know-your-section .link {
  @apply inline-flex items-center text-blue-600 hover:text-blue-700 font-medium transition-colors lg:-ml-4;
}
/* Rest of the styles remain the same */
.know-your-section .features {
  @apply space-y-6;
}
.know-your-section .feature-card {
  @apply bg-white p-6 rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-all duration-200 ease-in-out;
}
.know-your-section .feature-content {
  @apply flex items-center gap-4;
}
.know-your-section .icon-wrapper {
  @apply flex-shrink-0 p-4 bg-blue-50 rounded-lg text-blue-600 flex items-center justify-center;
}
.know-your-section .feature-text {
  @apply flex-1;
}
.know-your-section .feature-title {
  @apply text-lg font-semibold text-gray-900 mb-1;
}
.know-your-section .feature-description {
  @apply text-gray-600 leading-relaxed;
}

/* Mobile CSS */
@media (max-width: 768px) {
    .know-your-section .feature-content {
        @apply inline-block;
    }

    .know-your-section .icon-wrapper {
      @apply bg-transparent;
    }
}
