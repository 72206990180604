/* Accessibility styling for if users prefer reduced motion (ex: some people with vestibular motion disorders) */
@media (prefers-reduced-motion) {
    /* Remove animation from the enterprise integrations */
    .integration-item-primary-set, .integration-item-secondary-set {
        animation: none;
    }

    .integration-item-primary-set {
        overflow-x: scroll;
    }

    .integration-item-primary-set::before {
        content: "Your browser settings indicate that you prefer reduced motion, so we've turned off auto-scroll here. You can manually scroll horizontally through our enterprise integrations.";
        width: 200px;
        min-width: 200px;
        max-width: 100vw;
        text-align: left;
    }

    .integration-item-secondary-set {
        display: none;
    }

    /* Remove animations for the pulsing logo and spinning products. */
    .animation-circle {
        animation-name: none;
    }

    .animation-item {
        animation: none;
    }

    .item1 { transform: rotate(0deg) translateX(200px) rotate(0deg); }
    .item2 { transform: rotate(90deg) translateX(200px) rotate(-90deg); }
    .item3 { transform: rotate(180deg) translateX(200px) rotate(-180deg); }
    .item4 { transform: rotate(270deg) translateX(200px) rotate(-270deg); }

    @media (max-width: 1200px) {
        .item1 { transform: rotate(0deg) translateX(170px) rotate(0deg); }
        .item2 { transform: rotate(90deg) translateX(170px) rotate(-90deg); }
        .item3 { transform: rotate(180deg) translateX(170px) rotate(-180deg); }
        .item4 { transform: rotate(270deg) translateX(170px) rotate(-270deg); }
    }

    @media (max-width: 768px) {
        .item1 { transform: rotate(0deg) translateX(110px) rotate(0deg); }
        .item2 { transform: rotate(90deg) translateX(110px) rotate(-90deg); }
        .item3 { transform: rotate(180deg) translateX(110px) rotate(-180deg); }
        .item4 { transform: rotate(270deg) translateX(110px) rotate(-270deg); }
    }
}
