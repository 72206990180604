.about-us-grid {
    display: inline-grid;
    grid-template-columns: repeat(3, minmax(260px, 1fr));
    max-width: 1200px;
    gap: 30px;
    justify-items:
}

.about-us-item {
    padding: 1.5rem;
    border-style: solid;
    border-radius: 12px;
    text-align: left;
    max-width: 300px;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .about-us-grid {
        display: inline-block;
    }
}

@media (max-width: 768px) {
    .about-us-grid {
        width: 100%;
    }

    .about-us-item {
        max-width: none;
        padding: 0;
        border-style: none;
    }
}
